/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import React from 'react';
import dayjs from 'dayjs';
import { Typography } from 'styles';
import { colors } from '../../../styles/colors';
import Divider from '../../../assets/images/div.png';

const CardWrapper = styled.div`
  .inside_wrapper {
    background-color: ${colors.white};
    padding: 20px;
    border-radius: 15px;
  }
  .uper_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: 700;
  }
  .lower_wrapper {
    display: flex;
    justify-content: space-between;
  }
  .divider {
    width: 40px;
  }

  .trips_title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: ${colors.black};
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }
  .trips_date {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }
`;

const Card = ({ city, country, startDateTimeUTC, endDateTimeUTC }) => {
  return (
    <CardWrapper>
      <div className='inside_wrapper'>
        <div className='uper_wrapper'>
          <div className='trips_title'>
            {city && country ? `${city}, ${country}` : city || country || ''}
          </div>
        </div>
        <div className='lower_wrapper'>
          <div className='trips_date'>
            {dayjs(startDateTimeUTC).local().format('DD MMM YYYY')},&nbsp;
            {dayjs(startDateTimeUTC).local().format('HH:mm')}
          </div>
          <div>
            <img src={Divider} className='divider' alt='divider' />
          </div>
          <div className='trips_date'>
            {dayjs(endDateTimeUTC).local().format('DD MMM YYYY')},&nbsp;
            {dayjs(endDateTimeUTC).local().format('HH:mm')}
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default Card;
