import styled from 'styled-components';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../styles/colors';
import TripHeader from '../../molecules/trip-header';
import { Button } from '../../atoms';
import useAuth from '../../../utils/hooks/useAuth';
import { Typography } from '../../../styles';
import { extractAddressComponent } from '../../../utils/helpers';
import CatchMeCard from '../../molecules/cards/catch-me-card';

const JoinedCatchMeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${colors.main_color};

  .following_wrapper {
    background-color: ${colors.white};
    border-radius: 20px;
    width: 95%;
  }
  .form_section {
    background-color: ${colors.white};
    margin-bottom: 15px;
  }
  .text {
    color: ${colors.greyText};
    font-size: 16px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    line-height: 18px;
    text-align: center;
    font-weight: 400;
  }
  .button_section {
    text-align: center;
    margin-top: 15px;
  }
  .button {
    border-radius: 25px;
    padding: 10px 30px 10px 30px;
  }
  .card_wrapper {
    background-color: ${colors.faded_grey};
    padding: 20px 20px 5px 20px;
  }
  .button_connect {
    background-color: ${colors.white};
    border: 1px solid ${colors.main_color};
    color: ${colors.main_color};
    border-radius: 20px;
  }
  .title_section {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    line-height: 18px;
  }
  .email_text {
    color: ${colors.black};
    font-size: 16px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    line-height: 18px;
    text-align: center;
    font-weight: 400;
  }
  .text_section {
    font-size: 22px;
    text-align: center;
    line-height: 22px;
    margin-bottom: 10px;
    font-weight: 700;
    color: ${colors.main_color};
  }
  .plan_section {
    background-color: ${colors.yellow};
    padding: 25px 35px;
  }
  .heading_section {
    padding: 20px 10px;
  }
  .no_thanks {
    text-align: center;
    margin-top: 20px;
    color: ${colors.grey};
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
  @media screen and (min-width: 500px) {
    .following_wrapper {
      margin: 25px;
      width: auto;
    }
  }
`;

const JoinedCatchMe = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { guestUser } = useAuth();

  const [searchParams] = useSearchParams();
  const avatar = searchParams.get('avatar');
  const slug = searchParams.get('userSlug');
  const token = searchParams.get('token');
  const location = useLocation();
  const { catchData, user } = location.state || {};
  const { t } = useTranslation();

  const country = extractAddressComponent(catchData?.place?.address_components, 'country');
  const city = extractAddressComponent(catchData?.place?.address_components, 'locality');
  const address = extractAddressComponent(
    catchData?.place?.address_components,
    'administrative_area_level_1'
  );

  return (
    <JoinedCatchMeWrapper>
      <div className='following_wrapper'>
        <TripHeader
          avatarUrl={avatar}
          title={t('app.followCatchMe')}
          showAvatar={true}
          onClose={() =>
            navigate(
              `/catch/${slug}?token=${token}&guestEmail=${guestUser?.email}&catch_id=${params.name}`
            )
          }
        />
        <div className='title_section'>
          {t('app.joiningCatch')} <br /> {user?.fullName}
        </div>
        <div className='card_wrapper'>
          <CatchMeCard
            isCatchMeJoinCard={true}
            name={catchData?.place?.name}
            city={city}
            country={country}
            address={address}
            endDateTimeUTC={catchData?.endDateTimeUTC}
            startDateTimeUTC={catchData?.startDateTimeUTC}
            walk={catchData?.note}
          />
        </div>
        <div className='form_section'>
          <div className='heading_section'>
            <div className='text'>{t('app.receiveUpdates')}</div>
            <div className='email_text'>{guestUser.email}</div>
          </div>
          <div className='plan_section'>
            <div className='text_section'>
              {t('app.wantToPlan')} <br />
              {t('app.catchup', { name: user?.fullName })}
            </div>
            <div className='button_section'>
              <Button className='button' onClick={() => navigate(`/sign-up`)}>
                {t('app.createAccount')}
              </Button>
            </div>
          </div>

          <div
            className='no_thanks'
            onClick={() =>
              navigate(
                `/catch/${slug}?token=${token}&catch_id=${params.name}&guestEmail=${guestUser?.email}`
              )
            }
          >
            {t('app.noThanks')}
          </div>
        </div>
      </div>
    </JoinedCatchMeWrapper>
  );
};

export default JoinedCatchMe;
