import styled from 'styled-components';
import dayjs from 'dayjs';
import { Typography } from 'styles';
import { useTranslation } from 'react-i18next';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import Button from 'components/atoms/Button/index';
import { colors } from '../../../styles/colors';

dayjs.extend(utc);
dayjs.extend(timezone);

const CardWrapper = styled.div`
  background-color: ${colors.faded_grey};
  .inside_wrapper {
    max-width: 450px;
    background-color: ${colors.white};
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 15px;
  }
  .upper_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: 8px;
    max-width: 350px;
  }
  .lower_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .catch_me_title {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: ${colors.black};
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }
  .catch_me_date {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }
  .button_section {
    height: 28px;
  }
  .button_section_disabled {
    height: 28px;
    background-color: ${colors.light_grey};
    color: ${colors.white};
  }
`;

const CatchMeCard = ({
  name,
  city,
  endDateTimeUTC,
  startDateTimeUTC,
  isJoin,
  onButtonClick,
  walk,
  country,
  address,
  isCatchMeJoinCard = false,
}) => {
  const { t } = useTranslation();

  return (
    <CardWrapper>
      <div className='inside_wrapper'>
        <div className='lower_wrapper'>
          <div className='catch_me_date'>{t('app.catchMeTodayAt')}</div>
          {!isCatchMeJoinCard &&
            (isJoin ? (
              <Button disabled className='button_section_disabled'>
                {t('app.joined')}
              </Button>
            ) : (
              <Button className='button_section' onClick={onButtonClick}>
                {t('app.join')}
              </Button>
            ))}
        </div>
        <div className='upper_wrapper'>
          <div className='catch_me_title'>
            {name}, {city}, {country}, {address}
          </div>
        </div>
        <div className='lower_wrapper'>
          <div className='catch_me_date'>{walk}</div>
          <div className='catch_me_date'>
            {dayjs(startDateTimeUTC).local().format('HH:mm')}-
            {dayjs(endDateTimeUTC).local().format('HH:mm')}
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default CatchMeCard;
