import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { notification } from 'antd';
import useApiCall from './useApiCall';
import useAuth from './useAuth';
import { fetchCatchMeInfoApi } from '../../services/catch-me';

export const useCatchMe = () => {
  const [state, stateLoading, stateCall] = useApiCall(fetchCatchMeInfoApi);
  const { guestUser, isAuthInitialized } = useAuth();
  const {
    catch: catchData,
    user,
    expiryDate,
    valid,
    followed,
  } = state || {
    catch: {},
    user: null,
    expiryDate: null,
    valid: [],
    followed: [],
  };
  const [errors, setErrors] = useState({});

  const [userSlug, setUserSlug] = useState('');
  const [token, setToken] = useState('');
  const [catchId, setCatchId] = useState('');
  const [searchParams] = useSearchParams();
  const tokenNumber = searchParams.get('token');
  const guest = searchParams.get('guestEmail');
  const catchMe = searchParams.get('catch_id');
  const params = useParams();

  useEffect(() => {
    if (!isAuthInitialized) return;

    setUserSlug(params?.name);
    setToken(tokenNumber);
    setCatchId(catchMe);

    const guestEmailObj = {
      guestEmail: guest || guestUser?.email || null,
      token: tokenNumber,
      userSlug: params?.name,
      catchId: catchMe || null,
    };

    stateCall(
      guestEmailObj,
      () => {
        // notification.success({ message: 'Catch me data fetched successfully' });
      },
      error => {
        setErrors(error);
        notification.error({ message: error.message });
      }
    );
  }, [guestUser?.email, isAuthInitialized]);

  return {
    user,
    catchData,
    expiryDate,
    stateLoading,
    userSlug,
    token,
    valid,
    catchId,
    catchMe,
    followed,
    errors,
  };
};

export default useCatchMe;
