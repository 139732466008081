/* eslint-disable import/order */
import ResetWrapper from 'components/molecules/reset';
import JoinCatchMe from 'components/organisms/catch-me-screens/join-catch-me';
import JoinedCatchMe from 'components/organisms/catch-me-screens/joined-catch-me';
import VerificationCatchMe from 'components/organisms/catch-me-screens/verification-catch-me';
import JoinCatchUp from 'components/organisms/catch-up-screens/join-catch-up';
import JoinedCatchUp from 'components/organisms/catch-up-screens/joined-catch-up';
import VerificationCatchUp from 'components/organisms/catch-up-screens/verification-catch-up';
import Stepper from 'components/organisms/stepper';
import FollowTrip from 'components/organisms/trip-screens/follow-trip';
import FollowedTrip from '../components/organisms/trip-screens/followed-trip';
import Verification from 'components/organisms/trip-screens/verification';
import CatchMePage from 'pages/catchMe.page';
import CatchUpPage from 'pages/catchUp.page';
import HomePage from 'pages/home.page';
import Trips from 'pages/trips.page';

export const routes = {
  home: {
    key: 'home',
    path: '*',
    component: <HomePage />,
  },
  trips: {
    key: 'trips',
    path: '/trips/:name',
    component: <Trips />,
  },
  followTrip: {
    key: 'followTrip',
    path: '/trips-detail/:tripId',
    component: <FollowTrip />,
  },
  verification: {
    key: 'verification',
    path: '/verify/:tripId',
    component: <Verification />,
  },
  followingTrip: {
    key: 'followingTrip',
    path: '/following-trips/:tripId',
    component: <FollowedTrip />,
  },
  signUp: {
    key: 'signUp',
    path: '/sign-up',
    component: <Stepper />,
  },
  catchUpPage: {
    key: 'catchUpPage',
    path: '/catch-up/:name',
    component: <CatchUpPage />,
  },
  joinCatchUp: {
    key: 'joinCatchUp',
    path: '/catch-up-detail/:name',
    component: <JoinCatchUp />,
  },
  verificationCatchUp: {
    key: 'verificationCatchUp',
    path: '/verify-catch-up/:name',
    component: <VerificationCatchUp />,
  },
  joinedCatchUp: {
    key: 'joinedCatchUp',
    path: '/joined-catch-up/:name',
    component: <JoinedCatchUp />,
  },
  reset: {
    key: 'reset',
    path: '/reset',
    component: <ResetWrapper />,
  },
  catchMePage: {
    key: 'catchMePage',
    path: '/catch/:name',
    component: <CatchMePage />,
  },
  joinCatchMe: {
    key: 'joinCatchMe',
    path: '/catch-me-detail/:name',
    component: <JoinCatchMe />,
  },
  verificationCatchMe: {
    key: 'verificationCatchMe',
    path: '/verify-catch-me/:name',
    component: <VerificationCatchMe />,
  },
  joinedCatchMe: {
    key: 'joinedCatchMe',
    path: '/joined-catch-me/:name',
    component: <JoinedCatchMe />,
  },
};
