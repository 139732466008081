export const splitName = name => {
  if (!name) return [name];
  const nameParts = name?.split(' ');
  return nameParts.length > 1 ? nameParts : [name];
};

export const htmlEscape = str => {
  return String(str)
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/&/g, '&amp;'); // Move this to the end
};

export const extractAddressComponent = (addressComponents, type) => {
  const component = addressComponents?.find(item => item.types.includes(type));
  return component ? component.long_name : null;
};
