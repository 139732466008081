import styled from 'styled-components';
import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { colors } from '../../styles/colors';

const TripHeaderWrapper = styled.div`
  .inner_wrappers {
    display: flex;
    padding: 15px 20px;
    background-color: ${colors.white};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .icon {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    background-color: ${colors.white};
    cursor: pointer;
  }
`;

const TripHeader = ({ onClose }) => {
  return (
    <TripHeaderWrapper>
      <div className='inner_wrappers'>
        <div className='icon' on onClick={onClose}>
          <CloseOutlined style={{ color: colors.grey }} />
        </div>
      </div>
    </TripHeaderWrapper>
  );
};

export default TripHeader;
